@use 'variables';
@use 'mixins';

:root.default {
  @include mixins.spread-map(variables.$theme-map-default);
}

:root.ar {
  @include mixins.spread-map(variables.$theme-map-ar);
}

:root.theme-transition,
:root.theme-transition * {
  transition: background-color 1500ms;
  transition-delay: 0s;
}
